<template>
  <DetailTemplate
    :customClass="'project-detail detail-page'"
    v-if="getPermission('project:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton"
            width="400"
            height="28"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="130"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize">
            {{ getPageTitle }}
          </h1>
          <CustomStatus
            :status.sync="projectArr.status"
            endpoint="project/status"
          ></CustomStatus>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <template v-if="getPermission('project:update')">
        <v-menu
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="pageLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in projectMoreAction">
              <v-list-item
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
      <v-btn
        :disabled="pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="customerPersonDialog = true"
        color="cyan white--text"
      >
        Create Job
      </v-btn>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-tabs
        v-model="projectTab"
        background-color="transparent"
        color="cyan"
        class="custom-tab-transparent"
      >
        <template v-for="(tab, index) in tabs">
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            :key="index"
            :href="'#' + tab.key"
            :disabled="tab.disabled"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
              />
              <!--end::Svg Icon-->
            </span>
            {{ tab.title }}
          </v-tab>
        </template>
      </v-tabs>
      <!-- <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 70vh; position: relative"
      > -->
      <v-tabs-items v-model="projectTab">
        <v-tab-item v-if="getPermission('project:view')" :value="'project'">
          <OverviewCreate
            :projectArr.sync="projectArr"
            :project.sync="project"
            v-on:reload:project="getProject"
          ></OverviewCreate>
        </v-tab-item>
        <v-tab-item v-if="getPermission('task:view')" value="task">
          <Tasks :detail.sync="projectArr" type="project"></Tasks>
        </v-tab-item>
        <v-tab-item v-if="getPermission('task:view')" value="timesheet">
          <Timesheets :detail.sync="projectArr" type="project"></Timesheets>
        </v-tab-item>
        <v-tab-item value="contract" v-if="getPermission('contract:view')">
          <Contracts :detail.sync="projectArr" type="project"></Contracts>
        </v-tab-item>
        <v-tab-item :value="'costing'">
          <ProjectCostings
            :detail.sync="projectArr"
            type="project"
          ></ProjectCostings>
        </v-tab-item>
        <v-tab-item v-if="getPermission('job:view')" :value="'job'">
          <Tickets
            is-project
            :detail.sync="projectArr"
            type="project"
          ></Tickets>
        </v-tab-item>
        <v-tab-item v-if="getPermission('quotation:view')" value="quotation">
          <Quotations :detail.sync="projectArr" type="project"></Quotations>
        </v-tab-item>
        <v-tab-item
          v-if="getPermission('purchase-order:view')"
          value="purchase-order"
        >
          <PurchaseOrders
            :detail.sync="projectArr"
            type="project"
          ></PurchaseOrders>
        </v-tab-item>
        <v-tab-item v-if="getPermission('invoice:view')" :value="'invoice'">
          <Invoices
            is-project
            :detail.sync="projectArr"
            type="project"
          ></Invoices>
        </v-tab-item>
        <v-tab-item v-if="getPermission('history:view')" :value="'history'">
          <InternalHistoryDetail
            type="project"
            :type_id.sync="projectArr.id"
          ></InternalHistoryDetail>
        </v-tab-item>
      </v-tabs-items>
      <template v-if="customerPersonDialog">
        <CustomerPersonDialog
          :customerPersonDialog.sync="customerPersonDialog"
          :customer.sync="customerId"
          v-on:closeDialog="closeDialog"
          v-on:resetAll="resetAll"
          v-on:selectCustomerPerson="selectCustomerPerson"
        ></CustomerPersonDialog>
      </template>
      <template v-if="customerPropertyDialog">
        <CustomerPropertyDialog
          :customerPropertyDialog.sync="customerPropertyDialog"
          :customer.sync="customerId"
          v-on:closeDialog="closeDialog"
          v-on:resetAll="resetAll"
          v-on:selectCustomerProperty="selectCustomerProperty"
        ></CustomerPropertyDialog>
      </template>
      <!-- </perfect-scrollbar> -->
    </template>
  </DetailTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH } from "@/core/services/store/request.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import OverviewCreate from "@/view/pages/project/partials/Overview-Create";
import Tasks from "@/view/pages/partials/Detail/Tasks";
import Timesheets from "@/view/pages/partials/Detail/Timesheets";
import Tickets from "@/view/pages/partials/Detail/Tickets";
import Quotations from "@/view/pages/partials/Detail/Quotation";
import ProjectCostings from "@/view/pages/partials/Detail/ProjectCosting";
import PurchaseOrders from "@/view/pages/partials/Detail/PurchaseOrder";
import Contracts from "@/view/pages/partials/Detail/Contract";
import Invoices from "@/view/pages/partials/Detail/Invoice";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "project-detail",
  title: "Project",
  data() {
    return {
      project: 0,
      pageLoading: true,
      projectArr: {
        total_expenses: 0,
      },
      tabs: [
        {
          title: "Overview",
          icon: "mdi-phone",
          key: "project",
          disabled: false,
        },
        {
          title: "Tasks",
          icon: "mdi-credit-card",
          key: "task",
          disabled: false,
        },
        {
          title: "Timesheets",
          icon: "mdi-credit-card",
          key: "timesheet",
          disabled: false,
        },
        {
          title: "Jobs",
          icon: "mdi-credit-card",
          key: "job",
          disabled: false,
        },
        {
          title: "Project Costings",
          icon: "mdi-credit-card",
          key: "costing",
          disabled: false,
        },
        {
          title: "Quotations",
          icon: "mdi-account-multiple",
          key: "quotation",
          disabled: false,
        },
        {
          title: "Purchase Orders",
          icon: "mdi-account-multiple",
          key: "purchase-order",
          disabled: false,
        },
        {
          title: "Contracts",
          icon: "mdi-account-multiple",
          key: "contract",
          disabled: false,
        },
        {
          title: "Invoices",
          icon: "mdi-account-multiple",
          key: "invoice",
          disabled: false,
        },
        {
          title: "History",
          icon: "mdi-credit-card",
          key: "history",
          disabled: false,
        },
      ],
      projectMoreAction: [],
      quotationId: 0,
      projectId: 0,
      customerId: 0,
      personId: 0,
      propertyId: 0,
      customerPersonDialog: false,
      customerPropertyDialog: false,
    };
  },
  components: {
    Tasks,
    Tickets,
    Contracts,
    Invoices,
    Quotations,
    PurchaseOrders,
    Timesheets,
    DetailTemplate,
    OverviewCreate,
    CustomStatus,
    ProjectCostings,
    CustomerPersonDialog,
    CustomerPropertyDialog,
    InternalHistoryDetail,
  },
  methods: {
    selectCustomerPerson(param) {
      this.personId = param;
      this.closeDialog();
      this.checkJob();
      if (!this.propertyId || this.propertyId <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerProperty(param) {
      this.propertyId = param;
      this.closeDialog();
      this.checkJob();
    },
    resetAll() {
      this.customerId = 0;
      this.personId = 0;
      this.propertyId = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    checkJob() {
      const _this = this;
      if (_this.customerId > 0 && _this.personId > 0 && _this.propertyId > 0) {
        _this.$router.push(
          _this.getDefaultRoute("job.create", {
            query: {
              quotation: _this.quotationId ?? undefined,
              project: _this.projectId ?? undefined,
              customer: _this.customerId ?? undefined,
              contact_person: _this.personId ?? undefined,
              property: _this.propertyId ?? undefined,
            },
          })
        );
      }
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "duplicate":
          _this.$router.push(
            _this.getDefaultRoute("project.create", {
              query: {
                duplicate: _this.projectArr.id,
                customer: _this.lodash.toSafeInteger(
                  _this.projectArr.customer.customer
                ),
              },
            })
          );
          break;
        case "edit":
          _this.$router.push(
            _this.getDefaultRoute("project.update", {
              params: {
                id: _this.projectArr.id,
              },
              query: {
                customer: _this.lodash.toSafeInteger(
                  _this.projectArr.customer.customer
                ),
              },
            })
          );
          break;
        case "mark_as_inprogress":
          _this.updateProjectStatus(2);
          break;
        case "mark_as_hold":
          _this.updateProjectStatus(3);
          break;
        case "mark_as_cancel":
          _this.updateProjectStatus(4);
          break;
        case "mark_as_finish":
          _this.updateProjectStatus(5);
          break;
        case "download_pdf":
          break;
        case "print":
          break;
      }
    },
    updateProjectStatus(status) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "project/" + _this.project + "/status",
          data: { status },
        })
        .then(() => {
          _this.getProject();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getProject() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "projects/" + _this.project,
        })
        .then(({ data }) => {
          _this.projectId = data.id;
          _this.quotationId = data.quotation;
          _this.customerId = data.customer.customer;
          _this.projectArr = data;
          _this.projectMoreAction = data.more_actions;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.getProject();
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Project",
        route: "project",
      },
      {
        title: "Detail",
      },
    ]);
  },
  created() {
    const _this = this;
    _this.project = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (!_this.project || _this.project <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.projectTab = _this.$route.query.tab;
    }
  },
  computed: {
    getPageTitle() {
      let result = [];
      if (this.lodash.isEmpty(this.projectArr) === false) {
        if (this.projectArr.name) {
          result.push(this.projectArr.name);
        }
        if (this.projectArr.barcode) {
          result.push(this.projectArr.barcode);
        }
      }
      return result.join(" • ");
    },
    projectTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.projectTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "project";
      },
    },
  },
};
</script>
